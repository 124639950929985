<template>
  <modal class-name="pt-10 w-97" ref="modal">
    <div class="flex justify-center">
      <div
        class="flex items-center justify-center w-20 h-20 rounded-full"
        :class="{
          'bg-invest-green bg-opacity-10': isSuccess,
          'bg-invest-orange bg-opacity-10': isError,
        }"
      >
        <ion-icon
          :name="isSuccess ? 'checkmark' : 'alert'"
          class="text-3xl"
          :class="{
            'text-invest-green': isSuccess,
            'text-invest-orange': isError,
          }"
        />
      </div>
    </div>
    <div class="px-10 mt-8 text-center">
      <h3 class="text-2xl font-bold tracking-base">
        <slot name="title">Success!</slot>
      </h3>
      <p class="mt-2 mb-5 text-xs leading-loose text-invest-dark">
        <slot name="subtitle" />
      </p>
    </div>
    <slot name="body"></slot>
  </modal>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  PropType,
  toRefs,
  computed,
  SetupContext,
} from 'vue';

export default defineComponent({
  props: {
    alertType: {
      type: String as PropType<'success' | 'error'>,
      default: 'success',
    },
    title: {
      type: String,
      defualt: '',
    },
    body: {
      type: String,
      default: '',
    },
    subBody: {
      type: String,
      default: '',
    },
    buttons: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  setup(props, { slots }: SetupContext) {
    const modal = ref<any>(null);

    const { alertType, title, body, subBody } = toRefs(props);

    const isSuccess = computed(() => alertType.value === 'success');
    const isError = computed(() => alertType.value === 'error');

    const modalTitle = computed(() => slots.title || title.value);
    const modalBody = computed(() => slots.body || body.value);
    const modalSubBody = computed(() => slots.subBody || subBody.value);

    const open = () => modal.value.open();
    const close = () => modal.value.close();

    return {
      modal,
      open,
      isSuccess,
      isError,
      close,
      modalTitle,
      modalBody,
      modalSubBody,
    };
  },
});
</script>
