
import {
  defineComponent,
  ref,
  PropType,
  toRefs,
  computed,
  SetupContext,
} from 'vue';

export default defineComponent({
  props: {
    alertType: {
      type: String as PropType<'success' | 'error'>,
      default: 'success',
    },
    title: {
      type: String,
      defualt: '',
    },
    body: {
      type: String,
      default: '',
    },
    subBody: {
      type: String,
      default: '',
    },
    buttons: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  setup(props, { slots }: SetupContext) {
    const modal = ref<any>(null);

    const { alertType, title, body, subBody } = toRefs(props);

    const isSuccess = computed(() => alertType.value === 'success');
    const isError = computed(() => alertType.value === 'error');

    const modalTitle = computed(() => slots.title || title.value);
    const modalBody = computed(() => slots.body || body.value);
    const modalSubBody = computed(() => slots.subBody || subBody.value);

    const open = () => modal.value.open();
    const close = () => modal.value.close();

    return {
      modal,
      open,
      isSuccess,
      isError,
      close,
      modalTitle,
      modalBody,
      modalSubBody,
    };
  },
});
